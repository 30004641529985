import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/background/bg.jpg');

class Office_Building extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="OFFICE BUILDINGS" pagename="OFFICE BUILDINGS" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Office Buildings</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                Investing in office buildings can be a lucrative option for those seeking consistent income streams and the potential for long-term appreciation. At Foxland, we provide expert guidance in this domain, ensuring our clients make informed decisions.
                                            </span> </h2>
                                    </div>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>

                                            </span> </h2>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Location: </span>
                                            Prime locations with easy access to transportation and amenities are pivotal. Central business districts or high-demand areas typically yield higher occupancy rates and rental returns.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Tenant mix: </span>
                                            A diverse tenant base, encompassing both large and small entities, minimizes vacancy risks. Long-term leases with reputable tenants ensure stable cash flows.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Building Quality and Age: </span>
                                            The age and quality of a building can influence its value and appeal to potential tenants. It's crucial to assess these factors when considering an investment.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Lease Terms : </span>
                                            The specifics of lease agreements, from duration to rental rates and escalation clauses, can significantly impact a building's cash flows and overall value.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Market Trends: </span>
                                            Understanding current market rents, vacancy rates, and supply-demand dynamics is essential to gauge potential risks and opportunities.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Financing: </span>
                                            We assist investors in evaluating various financing options, ensuring they secure favorable interest rates and loan terms.</p>
                                        <p>A productive work environment can significantly influence one's output. Investing in office spaces, therefore, not only offers financial returns but also contributes to enhanced productivity. It's vital to meticulously assess each investment opportunity to minimize risks and optimize returns. Our team at Foxland is here to guide you every step of the way, ensuring your investment journey is smooth and rewarding.</p>

                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Office_Building;
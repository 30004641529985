import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/gallery/bg5.jpg');

class Sellers extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="Sellers" pagename="Sellers" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className='max-mid-container'>

                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-20 letter-spacing-1 font-weight-600'>Estimate of Value</span></p>
                                        <p>At Foxland, we prioritize providing our clients with accurate and comprehensive research to ensure they achieve optimal value for their properties when they decide to list them. Our experienced realtors specialize in conducting Comparative Market Analysis (CMA) to determine the fair market value range of properties. This expertise ensures our clients are well-equipped during negotiations, with our team's unwavering support from start to finish.</p>

                                    </div>
                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-20 letter-spacing-1 font-weight-600'>Rental Report</span></p>
                                        <p>We offer a detailed written report highlighting offerings from neighboring buildings. Our team's insights and guidance aim to unlock the full potential of your property, ensuring profitability and consistent cash flow for our clients.</p>

                                    </div>
                                    <div className='wt-post-title'>
                                        <p> <span className='text-black font-20 letter-spacing-1 font-weight-600'>Showcase</span></p>
                                        <p>Partnering with a seasoned real estate firm is crucial for effective property marketing. Foxland stands out as that trusted partner, offering unparalleled marketing strategies to position your property prominently in the market. Over the years, we've cultivated an expansive network in the real estate sector, encompassing lenders, inspectors, and attorneys, ensuring a seamless buying and selling experience. Our commitment is to secure the best possible deal for our clients' properties.</p>

                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Sellers;
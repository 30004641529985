import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/banner/bg-single.jpg');

class Single_family extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="SINGLE FAMILY INVESTMENT" pagename="SINGLE FAMILY INVESTMENT" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text font-36" style={{ textAlign: "center" }}>Single Family Investment</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                At Foxland, our experience and expertise position us as a valuable resource for clients keen on investing in single-family properties. Here's how we can assist:
                                            </span> </h2>
                                    </div>

                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>

                                            </span> </h2>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Market research and analysis: </span>We assist clients in identifying properties that align with their investment goals, considering factors like location, price, rental income potential, and property condition.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Property Valuation : </span>We provide property value estimates based on current market conditions and comparable sales in the vicinity.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Negotiation: </span>We can help you negotiate with sellers and their representatives to ensure they get the best deal possible.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Due Diligence: </span> We support clients in their due diligence efforts, from arranging property inspections to reviewing pertinent documents, ensuring the chosen property is a sound investment.</p>

                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Professional Referrals: </span>
                                            We can connect clients with other trusted professionals, such as attorneys and accountants, to facilitate the investment process.</p>


                                    </div>
                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                Considerations for Single-Family Property Investments:
                                            </span> </h2>
                                        <br />
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Location: </span>
                                            Opt for properties in sought-after areas with reputable schools, low crime rates, and convenient access to amenities.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Property Condition: </span>The property's state can significantly influence its value and rental income potential. Always opt for a thorough property inspection to pinpoint any issues.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Rental income potential:  </span>
                                            Seek properties with rental income prospects that surpass the mortgage payment and other associated costs.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Market trends: </span>Stay updated on market trends to invest in properties poised for value appreciation over time.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Financing: </span>Explore various financing options, comparing rates and terms to land the best possible deal.</p>
                                        <p>With these considerations in mind, our dedicated team simplifies the investment process, collaborating closely with you to realize your investment goals.</p>

                                    </div>

                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Single_family;
import React from 'react';
import Footer from '../Common/Footer';
import Banner from './../Segments/Banner';
import Header3 from '../Common/Header3';
import Footer2 from '../Common/Footer2';

var bnrimg = require('./../../images/background/bg.jpg');

class Land extends React.Component {
    render() {
        return (
            <>
                <Header3 />
                <div className="page-content">
                    <Banner title="LAND ACQUISITIONS" pagename="LAND ACQUISITIONS" bgimage={bnrimg.default} />
                    <div className="section-full p-tb90 square_shape1 square_shape3 tm-blog-single-wrap">
                        {/* SECTION CONTENTG START */}
                        <div className="section-full p-tb80 tm-shortcode-wrap">
                            {/* LOCATION BLOCK*/}
                            <div className="container">
                                {/* TITLE START */}
                                <div className="section-head text-left text-black">
                                    <h2 className="text-uppercase font-36" style={{ textAlign: "center" }}>Land Acquisitions</h2>
                                    <div className="wt-separator-outer">
                                        <div className="wt-separator bg-black" />
                                    </div>
                                </div>
                                <div className='max-mid-container'>

                                    <div className='wt-post-title'>
                                        <h2 className='post-title'>
                                            <span className='text-black font-20 letter-spacing-1 font-weight-600'>
                                                Foxland, a leading real estate brokerage, offers a suite of services to assist investors in land acquisition endeavors.
                                            </span> </h2>
                                        <br />
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Market Analysis: </span>
                                            We provide investors with a thorough market analysis of their desired area, encompassing current real estate market trends, property valuations, and potential investment opportunities.</p>

                                        <p><span className='text-black font-14 letter-spacing-1 font-weight-600'>Property Search:  </span>
                                            Our team aids investors in identifying properties that align with their criteria, such as location, size, zoning, and future development potential.</p>


                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Due Diligence: </span>
                                            We undertake rigorous due diligence on potential investment properties, encompassing property inspections, title searches, and environmental assessments, ensuring a sound investment.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'>Negotiation: </span>
                                            Our experts negotiate with sellers on investors' behalf, ensuring the acquisition is made at the most favorable terms.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Financing: </span>
                                            We guide investors in securing the best financing options, be it through mortgages or loans from financial institutions.</p>
                                        <p> <span className='text-black font-14 letter-spacing-1 font-weight-600'> Closing: </span>
                                            We streamline the closing process, ensuring all documentation is accurately executed and the transaction concludes smoothly.</p>
                                        <p>At Foxland, our primary objective is to help clients identify the ideal property for their investment. We navigate the challenges and complexities, ensuring a profitable and seamless investment journey. We stand by our commitment to deliver unparalleled service and results.</p>

                                    </div>
                                </div>

                                {/* TITLE END */}

                            </div>
                        </div>
                    </div>

                    {/* SECTION CONTENT END */}
                </div>


                <Footer2 />
            </>
        );
    };
};

export default Land;